import './ServiceDetails.css'

export const ServiceDetails = () => {
    return (
        <section class="service_section ">
            <div className="heading_container">
                    <h2>
                        Ус<span style={{color: '#FF0000'}}>лу</span>ги
                    </h2>
                    <p style={{ fontSize: '25px' }}>
                        Нашите услуги в сферата на проектирането
                    </p>
                </div>
                <div className="services-description">
                    <p>ПРОЕКТИРАНЕ НА СГРАДИ И СЪОРЪЖЕНИЯ: <span>Проектирането на всяка сграда започва с определяне нейната функционалност. Проектирането на всяка сграда започва с определяне нейната функционалност.</span>
                    </p>
                    <p>КОНСТРУКТИВНИ СТАНОВИЩА: <span>При нужда от изготвяне на конструктивни становища ... При нужда от изготвяне на конструктивни становища ...</span></p>
                    <p>ОБСЛЕДВАНЕ НА СТРОИТЕЛНИ КОНСТРУКЦИИ: <span>При нужда от обследване на строителни конструкции... При нужда от обследване на строителни конструкции...</span></p>
                    <p>АВТОРСКИ НАДЗОР: <span>Ние следим за стриктното изпълнение на изготвените прокети, което гарантира техния експлоатационен живот.</span></p>
                    <p>КОНСУЛТАЦИИ: <span>При нужда от консултации ние може да го направим по всеки въпрос касаещ Вашия бъдещ дом.</span></p>
                    <p>ИЗГОТВЯНЕ НА ПБЗ, ПУСО: <span>Изготвяне на проект по част ПБЗ се извършва когато ... Изготвяне на проект по част ПБЗ се извършва когато ...</span></p>
                </div>


        </section>
    )
}