
export const Footer = () => {
    return (
        <section className=" footer_section">
            <div className="container">
                <p>
                    &copy; <span id="displayYear"></span> All Rights Reserved
                </p>
            </div>
        </section>
    )
}