export const allProjects = [
    {
        _id: 0,
        name: 'Karose',
        location: 'кв.Кръстова вада, гр.София',
        purpose: 'Семейна къща',
        height: '2 етажа',
        area: '150кв.м.',
        description: 'Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция.',
        image: '/images/projects/architecture-family.jpg',
    },
    {
        _id: 1,
        name: 'Eterna',
        location: 'кв.Лозенец, гр.София',
        purpose: 'Жилищна сграда',
        height: '5 етажа',
        area: '950кв.м.',
        description: 'Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция. Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция. Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция.',
        image: '/images/projects/architecture-render.jpg',
    },
    {
        _id: 2,
        name: 'Levanda',
        location: 'кв.Хладилника, гр.София',
        purpose: 'Фамилна къща',
        height: '2 етажа',
        area: '250кв.м.',
        description: 'Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция.',
        image: '/images/projects/architecture-render-1.jpg',
    },
    {
        _id: 3,
        name: 'Niceta',
        location: 'кв.Бояна, гр.София',
        purpose: 'Едноетажна къща',
        height: '1 етажа',
        area: '150кв.м.',
        description: 'Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция.',
        image: '/images/projects/architecture-render-external.jpg',
    },
    {
        _id: 4,
        name: 'View Residence',
        location: 'кв.Драгалевци, гр.София',
        purpose: 'Жилищна сграда',
        height: '7 етажа',
        area: '1900кв.м.',
        description: 'Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция.',
        image: '/images/projects/apartments-high-rise.jpg',
    },
    {
        _id: 5,
        name: 'Park Residence',
        location: 'кв.Кючюк Пари, гр.Пловдив',
        purpose: 'Жилищна сграда',
        height: '2 етажа',
        area: '250кв.м.',
        description: 'Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция.',
        image: '/images/projects/new-home-house.jpg',
    },
    {
        _id: 6,
        name: 'Lovenda',
        location: 'гр. Констинброд',
        purpose: 'Семейна къща',
        height: '1 етажа',
        area: '230кв.м.',
        description: 'Проектът е разработен на база първоначалните изисквания на клиента. Използвана е монолитна стоманобетонна конструкция.',
        image: '/images/projects/white-and-brown-concrete-building.jpg',
    },

]

